import { createContext, useState } from 'react'

import { useUser } from '@/hooks/useUser'
import useUserInteractions from '@/hooks/useUserInteractions'
import { mutate } from 'swr'

const InteractionContext = createContext()

const InteractionProvider = ({ children }) => {
  const [loginOpen, setLoginOpen] = useState(false)
  const { user } = useUser()
  const { create } = useUserInteractions()

  const [cartVisible, setCartVisible] = useState(false)
  const [expandedPost, setExpandedPost] = useState(null)
  const [expandedExhibition, setExpandedExhibition] = useState(null)
  const [expandedCta, setExpandedCta] = useState(null)
  const [menuOpen, setMenuOpen] = useState(null)
  const [mobileShareData, setMobileShareData] = useState({
    open: false,
    post: {}
  })
  const [showMembershipModal, setShowMembershipModal] = useState(false)
  const [showSignUpModal, setShowSignUpModal] = useState(false)

  const getInteractions = postData => {
    const data = {}

    if (postData) {
      data.user = []
      data.post = []
    }

    return data
  }

  const handleInteraction = ({ type, data }) => {
    data.userId = user ? user.id : ''

    create(type, data)

    // mutate()
    setTimeout(() => {
      if (user) {
        mutate(['/api/interactions', user.id])
        mutate(['/api/interactions', user.id, data.post])
      }
    }, 750)
  }

  return (
    <InteractionContext.Provider
      value={{
        expandedCta,
        setExpandedCta,
        expandedExhibition,
        setExpandedExhibition,
        expandedPost,
        setExpandedPost,
        getInteractions,
        handleInteraction,
        loginOpen,
        setLoginOpen,
        menuOpen,
        setMenuOpen,
        mobileShareData,
        setMobileShareData,
        cartVisible,
        setCartVisible,
        showMembershipModal,
        setShowMembershipModal,
        showSignUpModal,
        setShowSignUpModal
      }}
    >
      {children}
    </InteractionContext.Provider>
  )
}

export { InteractionContext, InteractionProvider }
