const config = {
  additionalMetaTags: [
    {
      property: 'dc:creator',
      content: 'TwentySeven Works'
    }
  ],
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: process.env.NEXT_PUBLIC_PUBLIC_URL,
    site_name: 'Scramblers',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_PUBLIC_URL}/images/og.png`,
        width: 1231,
        height: 653,
        alt: 'Scramblers'
      }
    ]
  },
  titleTemplate: 'Scramblers | %s',
  twitter: {
    handle: '@wearescramblers',
    site: '@wearescramblers',
    cardType: 'summary_large_image'
  },
  additionalLinkTags: [
    {
      rel: 'shortcut icon',
      href: `${process.env.NEXT_PUBLIC_PUBLIC_URL}/favicon.png`
    }
  ]
}

if (process.env.NEXT_PUBLIC_FB_TRACKING_ID) {
  config.facebook = {
    appId: process.env.NEXT_PUBLIC_FB_TRACKING_ID
  }
}

// Don't allow indexing or following for search engines if
// not in production mode, or it's the beta site.
if (
  process.env.NODE_ENV !== 'production' ||
  process.env.NEXT_PUBLIC_PUBLIC_URL.indexOf('beta') > -1
) {
  config.dangerouslySetAllPagesToNoIndex = true
  config.dangerouslySetAllPagesToNoFollow = true
}

export default config
