import useSWR from 'swr'

const fetcher = url =>
  fetch(url)
    .then(res => res.json())
    .then(data => {
      return { user: data?.user || null }
    })

/**
 * Update the user specified by the email parameter.
 * @param {string} email - the email address of the user to update
 * @param {object} data - the data to update the user with
 * @returns {object} the updated user
 */
async function update (email, data) {
  const res = await fetch('/api/updateUser', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, data })
  })

  if (!res.ok) {
    throw new Error('Failed to update user')
  }

  const updated = await res.json()

  return updated
}

// Calls pages/api/user.js which uses the session data
// to find the user in the database.

export function useUser ({ redirectTo, redirectIfFound } = {}) {
  const { data, error, mutate } = useSWR('/api/user', fetcher)
  const userRecord = data?.user
  // const finished = Boolean(data)
  // const hasUser = Boolean(userRecord)

  const loading = !data && !error

  return {
    loading,
    mutate,
    user: error ? null : userRecord,
    update
  }
}
