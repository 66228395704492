import useSWR from 'swr'

const DOMAIN = process.env.NEXT_PUBLIC_PUBLIC_URL
const BASE_API_URL = '/api/interactions'

const fetcher = (...args) => {
  const filter = { userId: args[1] }

  if (args[2]) {
    filter.post = args[2]
  }

  const url = `${args[0]}?filter=${JSON.stringify(filter)}`

  return fetch(url).then(res => res.json())
}

async function create (type, data) {
  return fetch(`${DOMAIN}${BASE_API_URL}`, {
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      type,
      data
    }),
    method: 'POST',
    responseType: 'json'
  })
}

export default function useUserInteractions (user) {
  const params = [BASE_API_URL]

  // User ID or dummy ID.
  params.push(user?.id || 'XXXXXX')

  const { data, error, mutate } = useSWR(user ? params : null, fetcher)

  return {
    create,
    mutate,
    interactions: data,
    isLoading: !error && !data,
    isError: error
  }
}
