import React from 'react'
import Head from 'next/head'
import { CartProvider } from 'react-use-cart'
import { DefaultSeo } from 'next-seo'
import { InteractionProvider } from '@/contexts/InteractionContext'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
// import BugsnagPerformance from '@bugsnag/browser-performance'
import SEO from '../next-seo.config'
import 'react-medium-image-zoom/dist/styles.css'
import '../css/styles.css'

const pkg = require('../package.json')

Bugsnag.start({
  apiKey: 'a222ca4b3d9a86e214919dc7eadd3841',
  appVersion: pkg.version,
  plugins: [new BugsnagPluginReact()]
})

// BugsnagPerformance.start({ apiKey: 'a222ca4b3d9a86e214919dc7eadd3841' })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export default function MyApp ({ Component, pageProps }) {
  return (
    <>
      <Head />
      <ErrorBoundary>
        <InteractionProvider>
          <CartProvider>
            <DefaultSeo {...SEO} />
            <Component pageProps={pageProps} />
          </CartProvider>
        </InteractionProvider>
      </ErrorBoundary>
    </>
  )
}
